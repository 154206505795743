export default {
  logout: function (socket, router) {
    this.clearLocalStorage();
    socket.disconnect();
    router.push("/login");
  },
  clearLocalStorage: () => {
    delete window.localStorage.token;
    delete window.localStorage.user;
    delete window.localStorage.capabilities;
    delete window.localStorage.invitado;
  },
  checkLocalStorageForAuth: () => {
    try {
      const hasUser = !!JSON.parse(window.localStorage.user);
      return hasUser;
    } catch (error) {
      return false;
    }
  },
};
